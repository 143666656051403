@import './keyframes.scss';
@import './variables.scss';

.nav {
  // height: vw(82px);
  padding: vw(30px) vw(50px);
  
  display: flex;
  align-items: center;

  font-size: vw(17px);

  .logo {
    &-icon {
      height: 60px;
    }

    &-text {
      margin-left: vw(8px);

      &.en {
        height: vw(41px);
      }

      &.zh {
        height: vw(41px);
      }
    }
  }

  .option {
    justify-content: flex-end;

    div {
      padding-top: vw(10px);
      margin-right: vw(50px);
      position: relative;

      &.active::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(180deg, #69DC7B 0%, #63CECA 100%);
      }
    }

    .home {
      width: auto;
    }

    .privacy {
      width: auto;
    }

    .download {
      width: vw(165px);
      height: vw(54px);
      border-radius: vw(50px);
      box-shadow: 0px 4px 16px 0px #00000024;


      .download-icon {
        width: vw(17px);
        height: vw(18px);
        margin-left: vw(5px);
      }

      .QRCode-container {
        width: vw(300px);
        height: vw(300px);
        border-radius: vw(30px);
        top: vw(70px);
        right: vw(0px);

        .QRCode-inner {
          width: vw(243px);
          height: vw(243px);
          border: vw(10px) solid rgba($color: #ffffff, $alpha: 0.8);
          border-radius: vw(30px);

          .QRCode {
            width: vw(157px);
            height: vw(156px);

            .code,
            .prd-code {
              width: vw(114px);
              height: vw(116px);
            }
          }

          .QRCode-word {
            margin-top: vw(190px);
            font-size: vw(20px);
            line-height: vw(22px);
          }
        }
      }
    }
  }
}

.all-content {
  box-sizing: border-box;
  padding-top: vw(82px);
  // margin-top: -82px;
}

.title {
  height: vw(1117px);

  .container {
    .word-PC {
      width: vw(438px);
      height: vw(278px);
      font-size: vw(60px);
      line-height: vw(65px);
      top: vw(460px);
      left: vw(350px);

      .title-icon {
        width: vw(290px);
        height: vw(80px);
      }

      h5 {
        font-size: vw(17px);
        line-height: vw(18px);
        margin-top: vw(20px);
      }
    }

    .content {
      width: vw(452px);
      height: vw(770px);
      right: vw(300px);
      top: vw(80px);

      div {
        margin-bottom: vw(10px);
      }

      .chat-1 {
        width: vw(451px);
        height: vw(78px);

        // @include slideUpAnimation(slideUp770px, 770px);
      }

      .chat-2 {
        width: vw(451px);
        height: vw(89px);

        // @include slideUpAnimation(slideUp768px, 768px);
      }

      .chat-3 {
        width: vw(451px);
        height: vw(246px);

        // @include slideUpAnimation(slideUp858px, 858px);
      }

      .chat-4 {
        width: vw(186px);
        height: vw(187px);

        // @include slideUpAnimation(slideUp1182px, 1182px);
      }

      .chat-5 {
        width: vw(452px);
        height: vw(138px);

        // @include slideUpAnimation(slideUp1388px, 1388px);
      }
    }
  }

  .title-pic {
    margin: vw(65px) auto 0 auto;
    // width: 100%;
    min-width: 1780px;
    min-height: 855px;
  }
}

.crypto {
  height: vw(950px);

  .container {
    top: vw(90px);

    .word {
      width: vw(418px);
      height: vw(163px);
      font-size: vw(40px);

      h5 {
        margin-top: vw(20px);
        font-size: vw(17px);
        line-height: vw(18.5px);
      }

      &.active {
        // @include slideUpAnimation(slideUp689px, 689px);
      }
    }

    .phone {
      width: vw(604px);
      height: vw(670px);

      &.active {
        // @include slideUpAnimation(slideUp689px, 689px);
      }

      .crypto-icon {
        width: vw(116px);
        height: vw(169px);
        left: vw(90px);
        top: vw(225px);

        &.active {
          // @include slideUpAnimation(slideU495px, 495px);
        }
      }
    }
  }
}

.chat {
  height: vw(950px);

  .container {
    top: vw(140px);

    .word {
      width: vw(371px);
      height: vw(189px);
      font-size: vw(40px);

      h5 {
        font-size: vw(17px);
      }

      .icon-box {
        height: vw(76px);

        h5 {
          top: vw(70px);
          font-size: vw(14px);
        }

        .icon-1 {
          width: vw(56px);
          height: vw(53px);
        }

        .icon-2 {
          width: vw(52px);
          height: vw(51px);
        }

        .icon-3 {
          width: vw(63px);
          height: vw(53px);
        }

        .icon-4 {
          width: vw(56px);
          height: vw(48px);
        }
      }
    }
    .phone {
      width: vw(505px);
      height: vw(670px);

      &.active {
        .star-1,
        .star-2,
        .star-3,
        .star-4 {
          top: vw(140px);
          width: vw(33px);
          height: vw(31px);
        }

        .star-2 {
          width: vw(54px);
          height: vw(52px);
          top: vw(70px);
          left: vw(10px);
        }

        .star-3 {
          width: vw(26px);
          height: vw(24px);
          top: vw(200px);
          left: vw(300px);
        }

        .star-4 {
          left: vw(410px);
          top: vw(370px);
        }

        .icon-1 {
          top: vw(100px);
          left: vw(45px);
          width: vw(211px);
          height: vw(249px);
        }

        .icon-2 {
          top: vw(215px);
          right: vw(35px);
          width: vw(145px);
          height: vw(127px);
        }
      }
    }
  }
}

.video-chat {
  height: vw(950px);

  .container {
    top: vw(140px);

    .word {
      width: vw(408px);
      height: vw(145px);
      font-size: vw(40px);

      h5 {
        font-size: vw(17px);
        margin-top: vw(20px);
        line-height: vw(18.5px);
      }
    }

    .phone {
      width: vw(505px);
      height: vw(670px);

      &.active {
        // @include slideUpAnimation(slideUp489px, 489px);
      }

      .cloud1 {
        width: vw(103px);
        height: vw(68px);
        top: vw(100px);
        right: vw(45px);
        &.active {
          // @include slideLeftAnimation(slideLeft689px, 689px);
        }
      }

      .cloud2 {
        width: vw(63px);
        height: vw(42px);
        top: vw(50px);
        right: vw(0px);
        &.active {
          // @include slideLeftAnimation(slideLeft689px, 689px);
        }
      }

      .icon {
        width: vw(147px);
        height: vw(140px);
        top: vw(390px);
        right: vw(10px);
        &.active {
          // @include slideLeftAnimation(slideLeft689px, 689px);
        }
      }
    }
  }
}

.group-chat {
  height: vw(950px);

  .container {
    top: vw(140px);

    .word {
      width: vw(337px);
      height: vw(101px);
      font-size: vw(40px);

      h5 {
        margin-top: vw(20px);
        font-size: vw(17px);
        line-height: vw(18px);
      }
    }

    .phone {
      width: vw(505px);
      height: vw(670px);

      .chat1 {
        width: vw(330px);
        height: vw(671px);
        bottom: vw(100px);
        right: vw(180px);
        &.active {
          // @include slideRightAnimation(slideRight689px, 689px);
        }
      }

      .chat2 {
        width: vw(306px);
        height: vw(94px);
        top: vw(290px);
        right: vw(200px);
        &.active {
          // @include slideRightAnimation(slideRight689px, 689px);
        }
      }

      .chat3 {
        width: vw(306px);
        height: vw(71px);
        top: vw(405px);
        right: vw(200px);
        &.active {
          // @include slideRightAnimation(slideRight689px, 689px);
        }
      }

      .icon {
        width: vw(181px);
        height: vw(153px);
        top: vw(390px);
        right: vw(10px);
        &.active {
          // @include slideLeftAnimation(slideLeft689px, 689px);
        }
      }
    }
  }
}

.privacy-page {
  top: vw(82px);

  ul {
    margin-left: vw(15px);
  }

  .container {
    padding: vw(80px) vw(140px);
    margin: vw(60px) vw(250px);

    .date {
      font-size: vw(17px);
      line-height: vw(18px);
    }

    h1 {
      font-size: vw(64px);
      margin: vw(20px) 0;
    }

    p {
      margin: vw(10px) 0px vw(10px) 0px;
    }
  }
}

.user-policy-page {
  padding-top: vw(82px);
  height: calc(100vh - vw(82px));

  .container {
    padding: vw(80px) vw(140px);
    margin: vw(60px) vw(250px);

    h1 {
      font-size: vw(64px);
      margin: vw(20px) 0;
    }

    p {
      margin: vw(10px) 0px vw(20px) 0px;
    }
  }
}
