$pcrwd-breakpoint: 1920;
$theme-color: linear-gradient(
  180deg,
  rgba(29, 223, 107, 1) 0%,
  rgba(33, 208, 208, 1) 100%
);

// Function
@function vw($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: ($pcrwd-breakpoint * 0.01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw;
}

$mblrwd-breakpoint: 576;
// Function
@function vwm($target) {
  // 1 vw is equal to 1% of the viewport width
  $vw-context: ($mblrwd-breakpoint * 0.01) * 1px; // 1% viewport width
  @return ($target/$vw-context) * 1vw;
}

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}
