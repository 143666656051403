@import './keyframes.scss';
@import './variables.scss';

.App {
  overflow: unset;
  height: 100vh;
}

.nav {
  height: vwm(130px);
  padding: vwm(20px) vwm(20px);
  flex-direction: column;
  font-size: vwm(14px);
  font-family: Archivo;
  font-weight: 500;
  align-items: flex-start;

  .logo {
    &-icon {
      height: 34px
    }

    &-text {
      margin-left: vwm(8px);

      &.en {
        height: vwm(16px);
      }

      &.zh {
        height: 24px
      }
    }
  }

  .option {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: vwm(14px);

    div {
      padding-top: vwm(10px);
      margin-right: vwm(20px);
      position: relative;

      &.active::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(180deg, #69DC7B 0%, #63CECA 100%);
      }
    }

    .home {
      width: auto;
    }

    .privacy {
      width: auto;
    }

    .download {
      margin-left: 0;
      width: 59px;
      height: 29px;
      line-height: vwm(35px);
      border-radius: vwm(50px);
      padding: 0;
      box-shadow: 0px 4px 4px 0px #00000040;


      .download-icon {
        width: vwm(7px);
        height: vwm(12px);
        margin-left: vwm(5px);
        background: url('../../public/download_H5.png') no-repeat center center /
          contain;
        margin-top: vwm(1px);
      }

      .QRCode-container {
        width: vwm(300px);
        height: vwm(300px);
        border-radius: vwm(30px);
        top: vwm(50px);
        right: vwm(0px);

        .QRCode-inner {
          width: vwm(243px);
          height: vwm(243px);
          border: vwm(10px) solid rgba($color: #ffffff, $alpha: 0.8);
          border-radius: vwm(30px);

          .QRCode {
            width: vwm(157px);
            height: vwm(156px);

            .code {
              width: vwm(114px);
              height: vwm(116px);
            }
          }

          .QRCode-word {
            margin-top: vwm(190px);
            font-size: vwm(20px);
            line-height: vwm(22px);
          }
        }
      }
    }
  }
}

.all-content {
  padding-top: vwm(130px);
}

.title {
  height: vwm(593px * 1.54);
  position: relative;

  .title-pic {
    background: url('../../public/title_H5.png') no-repeat center bottom /
      contain;
    min-width: vwm(336px * 1.54);
    min-height: vwm(480px * 1.54);
    margin: vwm(50px * 1.54) auto 0 auto;
    overflow: hidden;
  }

  .container {
    width: 100%;
    height: 100%;

    .word-PC {
      display: none;
    }

    .content {
      width: vwm(334px * 1.54);
      height: vwm(181px * 1.54);
      left: 50%;
      transform: translateX(-50%);
      top: vwm(280px);
      position: absolute;

      .chat-1 {
        position: absolute;
        width: vwm(53px * 1.54);
        height: vwm(40px * 1.54);
        top: vwm(60px);
        left: vwm(20px);
        background: url('../../public/titleChatLeft1.png') no-repeat center
          center / contain;
        @include slideUpAnimation(slideUp770px, 770px);
        animation-delay: 0.1s;
      }

      .chat-2 {
        width: vwm(146px * 1.54);
        height: vwm(17px * 1.54);
        position: absolute;
        background: url('../../public/titleChatRight1.png') no-repeat center
          center / contain;
        @include slideUpAnimation(slideUp772px, 772px, 0.25);
        animation-delay: 0.1s;
        right: vwm(-30px);
        top: vwm(10px);
      }

      .chat-3 {
        width: vwm(41px * 1.54);
        height: vwm(31px * 1.54);
        position: absolute;
        top: vwm(100px);
        left: vwm(165px);
        background: url('../../public/titleChatLeft2.png') no-repeat center
          center / contain;
        @include slideUpAnimation(slideUp771px, 771px);
        animation-delay: 0.3s;
      }

      .chat-4 {
        width: vwm(146px * 1.54);
        height: vwm(21px * 1.54);
        position: absolute;
        top: vwm(50px);
        right: vwm(-30px);
        background: url('../../public/titleChatRight2.png') no-repeat center
          center / contain;
        @include slideUpAnimation(slideUp772px, 772px, 0.25);
        animation-delay: 0.3s;
      }
      .chat-5 {
        width: vwm(49px * 1.54);
        height: vwm(36px * 1.54);
        position: absolute;
        top: vwm(160px);
        left: vwm(65px);
        background: url('../../public/titleChatLeft3.png') no-repeat center
          center / contain;
        @include slideUpAnimation(slideUp771px, 771px);
        animation-delay: 0.5s;
      }

      .chat-6 {
        width: vwm(81px * 1.54);
        height: vwm(16px * 1.54);
        position: absolute;
        opacity: 0;
        display: block;
        top: vwm(95px);
        right: vwm(-25px);
        background: url('../../public/titleChatRight3.png') no-repeat center
          center / contain;
        @include slideUpAnimation(slideUp772px, 772px, 0.25);
        animation-delay: 0.5s;
      }

      .chat-7 {
        display: block;
        opacity: 0;
        width: vwm(40px * 1.54);
        height: vwm(30px * 1.54);
        position: absolute;
        top: vwm(230px);
        left: vwm(10px);
        background: url('../../public/titleChatLeft4.png') no-repeat center
          center / contain;
        @include slideUpAnimation(slideUp771px, 771px);
        animation-delay: 0.7s;
      }

      .chat-8 {
        width: vwm(146px * 1.54);
        height: vwm(16px * 1.54);
        position: absolute;
        opacity: 0;
        display: block;
        top: vwm(130px);
        right: vwm(-30px);
        background: url('../../public/titleChatRight4.png') no-repeat center
          center / contain;
        @include slideUpAnimation(slideUp772px, 772px, 0.25);
        animation-delay: 0.9s;
      }

      .chat-9 {
        width: vwm(146px * 1.54);
        height: vwm(16px * 1.54);
        position: absolute;
        opacity: 0;
        display: block;
        top: vwm(170px);
        right: vwm(-30px);
        background: url('../../public/titleChatRight5.png') no-repeat center
          center / contain;
        @include slideUpAnimation(slideUp772px, 772px, 0.25);
        animation-delay: 1.1s;
      }

      .chat-10 {
        width: vwm(146px * 1.54);
        height: vwm(17px * 1.54);
        position: absolute;
        opacity: 0;
        display: block;
        top: vwm(205px);
        right: vwm(-70px);
        background: url('../../public/titleChatRight6.png') no-repeat center
          center / contain;
        @include slideUpAnimation(slideUp772px, 772px, 0.25);
        animation-delay: 1.3s;
      }
    }
  }

  .word-H5 {
    margin-top: vwm(25px);
    width: vwm(211px * 1.54);
    height: vwm(61px * 1.54);
    display: block;
    font-family: Archivo;
    font-size: vwm(20px * 1.54);
    font-weight: 600;
    margin: vwm(50px) vwm(0px) vwm(0px) vwm(40px);

    h5 {
      font-family: PingFang SC;
      font-size: vwm(12px * 1.54);
      font-weight: 400;
      line-height: vwm(17px * 1.54);
      margin-top: vwm(20px);
    }

    .title-icon {
      position: unset;
      background: url('../../public/titleIcon_H5.png') no-repeat center center /
        contain;
      width: vwm(165px * 1.54);
      height: vwm(45px * 1.54);
      margin: vwm(10px) 0 0 vwm(15px);
    }
  }
}

.crypto {
  height: vwm(812 * 1.54px);

  .container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    background: url('../../public/cryptoBackground.png') no-repeat center
      vwm(600px) / contain;

    .word {
      width: vwm(295px * 1.54);
      height: vwm(100px * 1.54);
      font-family: Archivo;
      font-size: vwm(20px * 1.54);
      font-weight: 600;
      line-height: vwm(22px * 1.54);
      margin-top: vwm(40px);

      h5 {
        font-family: PingFang SC;
        font-size: vwm(12px * 1.54);
        font-weight: 400;
        line-height: vwm(17px * 1.54);
        margin-top: 20px;
      }

      &.active {
        animation-delay: 0s;
      }
    }

    .phone {
      width: vwm(225px * 1.54);
      height: vwm(487px * 1.54);
      background: url('../../public/cryptoPhone_H5.png') no-repeat center center /
        contain;

      &.active {
        animation-delay: 0.5s;
      }

      .crypto-icon {
        width: vwm(86px * 1.54);
        height: vwm(126px * 1.54);
        left: vwm(90px);
        top: vwm(290px);

        &.active {
          @include slideUpAnimation(slideUp774px, 774px);
          animation-delay: 0.7s;
        }
      }
    }
  }
}

.chat {
  height: vwm(812px * 1.54);

  .container {
    // width: vwm(318px * 1.54);
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    .word {
      width: vwm(295px * 1.54);
      height: vwm(108px * 1.54);
      order: 0;
      font-family: Archivo;
      font-size: vwm(20px * 1.54);
      font-weight: 600;
      line-height: vwm(22px * 1.54);
      margin-top: vwm(35px);

      &.active {
        animation-delay: 0s;
      }

      h5 {
        font-family: PingFang SC;
        font-size: vwm(12px * 1.54);
        font-weight: 400;
        line-height: vwm(17px * 1.54);
      }

      .icon-box {
        height: vwm(44px * 1.54);
        width: vwm(222px * 1.54);
        display: flex;
        justify-content: space-between;

        h5 {
          font-family: Archivo;
          font-size: vwm(10px * 1.54);
          font-weight: 500;
          width: 160%;
          top: vwm(60px);
        }

        .icon-1 {
          width: vwm(30px * 1.54);
          height: vwm(29px * 1.54);
        }

        .icon-2 {
          width: vwm(28px * 1.54);
          height: vwm(29px * 1.54);
        }

        .icon-3 {
          width: vwm(35px * 1.54);
          height: vwm(29px * 1.54);
        }

        .icon-4 {
          width: vwm(31px * 1.54);
          height: vwm(26px * 1.54);
        }
      }
    }

    .phone {
      width: vwm(225px * 1.54);
      height: vwm(488px * 1.54);
      margin-right: vwm(25px);
      order: 1;
      margin-bottom: vwm(120px);
      background: url('../../public/chatPhone_H5.png') no-repeat center center /
        contain;

      &.active {
        animation-delay: 0.5s;

        .star-1,
        .star-2,
        .star-3 {
          display: none;
        }

        .star-4 {
          width: vwm(23px * 1.54);
          height: vwm(22px * 1.54);
          left: vwm(350px);
          top: vwm(405px);
          @include slideLeftAnimation(slideLeft289px, 289px);
          animation-delay: 0.8s;
        }

        .icon-1 {
          position: absolute;
          top: vwm(160px);
          left: vwm(-30px);
          width: vwm(151px * 1.54);
          height: vwm(107px * 1.54);
          @include slideRightAnimation(slideRight289px, 289px);
          animation-delay: 0.8s;
        }

        .icon-2 {
          position: absolute;
          top: vwm(225px);
          right: vwm(-70px);
          width: vwm(109px * 1.54);
          height: vwm(95px * 1.54);
          @include slideLeftAnimation(slideLeft389px, 389px);
          animation-delay: 0.8s;
        }
      }
    }
  }
}

.video-chat {
  height: vwm(812px * 1.54);

  .container {
    top: vwm(80px);
    height: 85%;
    flex-direction: column;
    justify-content: space-between;

    .word {
      font-family: Archivo;
      font-size: vwm(20px * 1.54);
      font-weight: 600;
      line-height: vwm(22px * 1.54);
      width: vwm(296px * 1.54);
      height: vwm(84px * 1.54);

      h5 {
        font-family: PingFang SC;
        font-size: vwm(12px * 1.54);
        font-weight: 400;
        line-height: vwm(17px * 1.54);
        margin-top: vwm(20px);
      }

      &.active {
        animation-delay: 0s;
      }
    }

    .phone {
      width: vwm(225px * 1.54);
      height: vwm(487px * 1.54);

      &.active {
        animation-delay: 0.5s;
      }

      .cloud1 {
        width: vwm(63px * 1.54);
        height: vwm(41px * 1.54);
        top: vwm(135px);
        left: vwm(-65px);
        &.active {
          @include slideRightAnimation(slideRight689px, 689px);
          animation-delay: 0.8s;
        }
      }

      .cloud2 {
        width: vwm(46px * 1.54);
        height: vwm(30px * 1.54);
        top: 80px;
        right: -30px;
        &.active {
          @include slideLeftAnimation(slideLeft689px, 689px);
          animation-delay: 0.8s;
        }
      }

      .icon {
        width: vwm(77px * 1.54);
        height: vwm(71px * 1.54);
        top: vwm(495px);
        right: vwm(-50px);
        &.active {
          @include slideLeftAnimation(slideLeft689px, 689px);
          animation-delay: 0.8s;
        }
      }
    }
  }
}

.group-chat {
  height: vwm(812px * 1.54);

  .container {
    top: vwm(120px);
    height: 80%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;

    .word {
      width: vwm(296px * 1.54);
      height: vwm(62px * 1.54);
      order: 0;
      font-family: Archivo;
      font-size: vwm(20px * 1.54);
      font-weight: 600;
      line-height: vwm(22px * 1.54);

      h5 {
        font-family: PingFang SC;
        font-size: vwm(12px * 1.54);
        font-weight: 400;
        line-height: vwm(17px * 1.54);
      }

      &.active {
        animation-delay: 0s;
      }
    }

    .phone {
      width: vwm(295px * 1.54);
      height: vwm(487px * 1.54);
      order: 1;
      left: vwm(-65px);

      &.active {
        animation-delay: 0.5s;
      }

      .chat1 {
        width: vwm(204px * 1.54);
        height: vwm(40px * 1.54);
        top: vwm(260px);
        right: vwm(80px);
        &.active {
          animation-delay: 0.8s;
        }
      }

      .chat2 {
        width: vwm(183px * 1.54);
        height: vwm(55px * 1.54);
        top: vwm(350px);
        right: vwm(100px);
        &.active {
          animation-delay: 0.9s;
        }
      }

      .chat3 {
        width: vwm(171px * 1.54);
        height: vwm(40px * 1.54);
        top: vwm(465px);
        right: vwm(115px);
        &.active {
          animation-delay: 1s;
        }
      }

      .icon {
        width: vwm(113px * 1.54);
        height: vwm(95px * 1.54);
        top: vwm(490px);
        right: vwm(-60px);
        &.active {
          animation-delay: 1.1s;
        }
      }
    }
  }
}

.privacy-page {
  top: vwm(130px);
  overflow: hidden;
  line-height: 1.5rem;

  .container {
    height: calc(100% + vwm(130px));
    margin: 0;
    width: 100vw;
    box-sizing: border-box;

    font-size: vwm(16px * 1.54);

    ul {
      margin-left: vwm(15px);
    }

    .date {
      font-size: vwm(10px * 1.54);
      line-height: vwm(14px * 1.54);
    }

    h1 {
      font-size: vwm(20px * 1.54);
      margin: vwm(20px * 1.54) 0;
    }
    p {
      margin: vw(10px) 0px vw(10px) 0px;
      line-height: 1.7rem;
    }
  }
}

.user-policy-page {
  padding-top: vwm(130px);
  height: calc(100vh - vwm(130px));
  line-height: 1.7rem;

  font-size: vwm(16px * 1.54);

  .container {
    margin: 0;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;

    h1 {
      font-size: vwm(20px * 1.54);
      margin: vwm(10px * 1.54) 0;
    }

    p {
      margin: vwm(10px) 0px vwm(20px) 0px;
      line-height: 1.7rem;
    }
  }
}
