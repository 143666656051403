@import './keyframes.scss';

.App {
  max-width: 100vw;
  overflow: hidden;
  // transform: translate3d(0, 0, 0);
}

.nav {
  width: 100vw;
  // height: 82px;
  display: flex;
  justify-content: space-between;
  padding: 30px 50px;
  padding-bottom: 0;
  box-sizing: border-box;
  position: fixed;
  z-index: 10;
  background: #ffffff;

  font-family: Archivo;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #4b4b4b;

  .logo {
    display: flex;
    align-items: center;
  }

  .option {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 484px;
    // height: 34px;
    cursor: pointer;

    div {
      padding-top: 10px;
      text-align: center;

      // &::after {
      //   margin-top: 5px;
      //   display: block;
      //   content: '';
      //   background: $theme-color;
      //   width: 100%;
      //   height: 3px;
      //   opacity: 0;
      //   transition: all 0.35s ease-in-out;
      // }

      &.active::after {
        opacity: 1;
      }
    }

    .home {
      width: 34px;
    }

    .privacy {
      width: 85px;
    }

    .download {
      width: 165px;
      height: 34px;
      background: $theme-color;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      color: #ffffff;
      position: relative;
      align-items: center;
      padding: 0;

      &::after {
        display: none;
      }

      .download-icon {
        display: inline-block;
        width: 17px;
        height: 18px;
        background: url('../../public/download_PC.png') no-repeat center center /
          contain;
        margin-left: 5px;
      }

      .QRCode-container {
        position: absolute;
        width: 300px;
        height: 300px;
        background: $theme-color;
        border-radius: 30px;
        z-index: 1;
        top: 50px;
        right: 0px;
        opacity: 0;
        transition: opacity 0.4s ease-in-out;

        &.active {
          opacity: 1;
        }

        .QRCode-inner {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 243px;
          height: 243px;
          border: 10px solid rgba($color: #ffffff, $alpha: 0.8);
          transform: translate(-50%, -50%);
          border-radius: 30px;

          .QRCode {
            width: 157px;
            height: 156px;
            background: url('../../public/vector_PC.png') no-repeat center
              center / contain;
            position: absolute;
            left: 50%;
            top: 45%;
            transform: translate(-50%, -50%);

            .code,
            .prd-code {
              width: 114px;
              height: 116px;
              background: url('../../public/qrcode.png') no-repeat center center /
                contain;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              border-radius: 10px;
            }

            .prd-code {
              background: url('../../public/qrcodePrd.png') no-repeat center
                center / contain;
            }
          }

          .QRCode-word {
            margin-top: 190px;
            font-family: Archivo;
            font-size: 20px;
            font-weight: 600;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.all-content {
  box-sizing: border-box;
  padding-top: 82px;
  // margin-top: -82px;
}

.title {
  width: 100vw;
  height: 1117px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;

  .container {
    width: 1780px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-around;
    align-items: center;

    .word-PC {
      width: 438px;
      height: 278px;
      font-family: Archivo;
      font-size: 60px;
      font-weight: 600;
      line-height: 65px;
      color: #ffffff;
      margin-bottom: 40px;

      .title-icon {
        margin-top: 30px;
        width: 290px;
        height: 80px;
        background: url('../../public/titleIcon_PC.png') no-repeat center center /
          contain;
      }

      h5 {
        font-family: Archivo;
        font-size: 17px;
        font-weight: 400;
        line-height: 18px;
        margin-top: 20px;
      }
    }

    .content {
      width: 452px;
      height: 770px;
      margin-bottom: 130px;

      div {
        margin-bottom: 10px;
      }

      .chat-1 {
        background: url('../../public/title-chat-1_PC.png') no-repeat center
          center / contain;
        width: 451px;
        height: 78px;

        @include slideUpAnimation(slideUp770px, 770px);
        animation-delay: 0.8s;
        opacity: 0;
      }

      .chat-2 {
        background: url('../../public/title-chat-2_PC.png') no-repeat center
          center / contain;
        width: 451px;
        height: 89px;

        @include slideUpAnimation(slideUp768px, 768px);
        animation-delay: 0.95s;
        opacity: 0;
      }

      .chat-3 {
        background: url('../../public/title-chat-3_PC.png') no-repeat center
          center / contain;
        width: 451px;
        height: 246px;

        @include slideUpAnimation(slideUp858px, 858px);
        animation-delay: 1.05s;
        opacity: 0;
      }

      .chat-4 {
        background: url('../../public/title-chat-4_PC.png') no-repeat center
          center / contain;
        width: 186px;
        height: 187px;
        float: right;

        @include slideUpAnimation(slideUp1182px, 1182px);
        animation-delay: 1.2s;
        opacity: 0;
      }

      .chat-5 {
        background: url('../../public/title-chat-5_PC.png') no-repeat center
          center / contain;
        width: 452px;
        height: 138px;
        float: right;

        @include slideUpAnimation(slideUp1388px, 1388px);
        animation-delay: 1.35s;
        opacity: 0;
      }

      .chat-6,
      .chat-7,
      .chat-8,
      .chat-9,
      .chat-10 {
        display: none;
      }
    }
  }

  div {
    max-width: 100vw;
  }

  .word-H5 {
    display: none;
  }

  .title-pic {
    margin: 65px auto 0 auto;
    // min-width: 1780px;
    // min-height: 855px;
    background: url('../../public/titile_PC.png') no-repeat center center;
    background-size: cover;
  }
}

.crypto {
  width: 100vw;
  height: 950px;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;

  .container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 90px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .word {
      opacity: 0;
      width: 418px;
      height: 163px;
      font-family: Archivo;
      font-size: 40px;
      font-weight: 600;
      color: #4b4b4b;

      span {
        background: $theme-color;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      h5 {
        margin-top: 20px;
        font-size: 17px;
        font-weight: 400;
        line-height: 18.5px;
      }

      &.active {
        @include slideUpAnimation(slideUp689px, 689px);
        animation-delay: 0.5s;
      }
    }

    .phone {
      width: 604px;
      height: 670px;
      background: url('../../public/cryptoPhone.png') no-repeat center center /
        contain;
      position: relative;
      opacity: 0;

      &.active {
        @include slideUpAnimation(slideUp689px, 689px);
      }

      .crypto-icon {
        width: 116px;
        height: 169px;
        background: url('../../public/cryptoIcon.png') no-repeat center center /
          contain;
        position: absolute;
        left: 90px;
        top: 225px;
        opacity: 0;

        &.active {
          @include slideUpAnimation(slideU495px, 495px);
          animation-delay: 0.5s;
        }
      }
    }
  }
}

.chat {
  width: 100vw;
  height: 950px;
  background: #71c2ba;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;

  .container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 140px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .word {
      width: 371px;
      height: 189px;
      opacity: 0;
      font-family: Archivo;
      font-size: 40px;
      font-weight: 600;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h5 {
        font-size: 17px;
        font-weight: 400;
      }

      .icon-box {
        height: 76px;
        display: flex;
        justify-content: space-between;

        h5 {
          width: 160%;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 70px;
          font-size: 14px;
          text-align: center;
        }

        .icon-1 {
          width: 56px;
          height: 53px;
          background: url('../../public/chatIcon1.png') no-repeat center center /
            contain;
          position: relative;
        }

        .icon-2 {
          width: 52px;
          height: 51px;
          background: url('../../public/chatIcon2.png') no-repeat center center /
            contain;
          position: relative;
        }

        .icon-3 {
          width: 63px;
          height: 53px;
          background: url('../../public/chatIcon3.png') no-repeat center center /
            contain;
          position: relative;
        }

        .icon-4 {
          width: 56px;
          height: 48px;
          background: url('../../public/chatIcon4.png') no-repeat center center /
            contain;
          position: relative;
        }
      }

      &.active {
        @include slideUpAnimation(slideUp689px, 689px);
        animation-delay: 0.5s;
      }
    }

    .phone {
      width: 505px;
      height: 670px;
      background: url('../../public/chatPhone.png') no-repeat center center /
        contain;
      position: relative;
      opacity: 0;

      &.active {
        @include slideUpAnimation(slideUp489px, 489px);

        .star-1,
        .star-2,
        .star-3,
        .star-4 {
          position: absolute;
          top: 140px;
          width: 33px;
          height: 31px;
          background: url('../../public/chatStar1_PC.png') no-repeat center
            center / contain;
          @include slideUpAnimation(slideUp689px, 689px);
          animation-delay: 0.2s;
          opacity: 0;
        }

        .star-2 {
          width: 54px;
          height: 52px;
          top: 70px;
          left: 10px;
          animation-delay: 0.3s;
        }

        .star-3 {
          width: 26px;
          height: 24px;
          top: 200px;
          left: 300px;
          animation-delay: 0.4s;
        }

        .star-4 {
          left: 410px;
          top: 370px;
          animation-delay: 0.5s;
        }

        .icon-1 {
          position: absolute;
          top: 100px;
          left: 45px;
          width: 211px;
          height: 249px;
          background: url('../../public/chatIcon1_PC.png') no-repeat center
            center / contain;
          opacity: 0;
          @include slideUpAnimation(slideUp689px, 689px);
          animation-delay: 0.6s;
        }

        .icon-2 {
          position: absolute;
          top: 215px;
          right: 35px;
          width: 145px;
          height: 127px;
          background: url('../../public/chatIcon2_PC.png') no-repeat center
            center / contain;
          opacity: 0;
          @include slideUpAnimation(slideUp689px, 689px);
          animation-delay: 0.7s;
        }
      }
    }
  }
}

.video-chat {
  width: 100vw;
  height: 950px;
  background: #71c2ba;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;

  .container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 140px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .word {
      width: 408px;
      height: 145px;
      opacity: 0;
      font-family: Archivo;
      font-size: 40px;
      font-weight: 600;
      color: #ffffff;

      h5 {
        font-size: 17px;
        font-weight: 400;
        margin-top: 20px;
        line-height: 18.5px;
      }

      &.active {
        @include slideUpAnimation(slideUp689px, 689px);
        animation-delay: 0.5s;
      }
    }

    .phone {
      width: 505px;
      height: 670px;
      background: url('../../public/videoPhone.png') no-repeat center center /
        contain;
      position: relative;
      opacity: 0;

      &.active {
        @include slideUpAnimation(slideUp489px, 489px);
      }

      .cloud1 {
        width: 103px;
        height: 68px;
        background: url('../../public/videoCloud1.png') no-repeat center center /
          contain;
        position: absolute;
        top: 100px;
        right: 45px;
        opacity: 0;
        &.active {
          @include slideLeftAnimation(slideLeft689px, 689px);
          animation-delay: 0.5s;
        }
      }

      .cloud2 {
        width: 63px;
        height: 42px;
        background: url('../../public/videoCloud1.png') no-repeat center center /
          contain;
        position: absolute;
        top: 50px;
        right: 0px;
        opacity: 0;
        &.active {
          @include slideLeftAnimation(slideLeft689px, 689px);
          animation-delay: 0.6s;
        }
      }

      .icon {
        width: 147px;
        height: 140px;
        background: url('../../public/videoIcon.png') no-repeat center center /
          contain;
        position: absolute;
        top: 390px;
        right: 10px;
        opacity: 0;
        &.active {
          @include slideLeftAnimation(slideLeft689px, 689px);
          animation-delay: 0.7s;
        }
      }
    }
  }
}

.group-chat {
  width: 100vw;
  height: 950px;
  background: #f7ffef;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;

  .container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 140px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .word {
      width: 337px;
      height: 101px;
      font-family: Archivo;
      opacity: 0;
      font-size: 40px;
      font-weight: 600;
      color: #4b4b4b;

      h5 {
        margin-top: 20px;
        font-size: 17px;
        font-weight: 400;
        line-height: 18px;
      }

      &.active {
        @include slideUpAnimation(slideUp689px, 689px);
        animation-delay: 0.5s;
      }
    }

    .phone {
      width: 505px;
      height: 670px;
      background: url('../../public/groupPhone.png') no-repeat center center /
        contain;
      position: relative;
      opacity: 0;

      &.active {
        @include slideUpAnimation(slideUp489px, 489px);
      }

      .chat1 {
        width: 330px;
        height: 671px;
        background: url('../../public/groupChat1.png') no-repeat center center /
          contain;
        position: absolute;
        bottom: 100px;
        right: 180px;
        opacity: 0;
        &.active {
          @include slideRightAnimation(slideRight689px, 689px);
          animation-delay: 0.6s;
        }
      }

      .chat2 {
        width: 306px;
        height: 94px;
        background: url('../../public/groupChat2.png') no-repeat center center /
          contain;
        position: absolute;
        top: 290px;
        right: 200px;
        opacity: 0;
        &.active {
          @include slideRightAnimation(slideRight689px, 689px);
          animation-delay: 0.7s;
        }
      }

      .chat3 {
        width: 306px;
        height: 71px;
        background: url('../../public/groupChat3.png') no-repeat center center /
          contain;
        position: absolute;
        top: 405px;
        right: 200px;
        opacity: 0;
        &.active {
          @include slideRightAnimation(slideRight689px, 689px);
          animation-delay: 0.8s;
        }
      }

      .icon {
        width: 181px;
        height: 153px;
        background: url('../../public/groupIcon.png') no-repeat center center /
          contain;
        position: absolute;
        top: 390px;
        right: 10px;
        opacity: 0;
        &.active {
          @include slideLeftAnimation(slideLeft689px, 689px);
          animation-delay: 0.9s;
        }
      }
    }
  }
}

.privacy-page {
  position: relative;
  top: 82px;
  background: #f7ffef;

  b {
    font-weight: bold;
  }

  ul {
    list-style: disc;
    list-style-position: inside;
    margin-left: 15px;
  }

  .container {
    background: #ffffff;
    padding: 80px 140px;
    margin: 60px 250px;

    .date {
      font-family: Archivo;
      font-size: 17px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: right;
      color: #9a9a9a;
    }

    h1 {
      font-size: 64px;
      font-weight: 600;
      margin: 20px 0;
      text-align: center;
    }

    p {
      margin: 10px 0px 10px 0px;
      line-height: 1.2rem;
    }
  }
}

.user-policy-page {
  padding-top: 82px;
  height: calc(100vh - 82px);
  background: #f7ffef;

  .container {
    background: #ffffff;
    padding: 80px 140px;
    margin: 60px 250px;

    h1 {
      font-size: 64px;
      font-weight: 600;
      margin: 20px 0;
      text-align: center;
    }

    b {
      font-weight: bold;
      display: block;
    }

    p {
      margin: 10px 0px 20px 0px;
      line-height: 1.2rem;
    }
  }
}

.download-page {
  background: url('../../public/download-bg.png') no-repeat;
  background-size: cover;
  padding: 20%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  box-sizing: border-box;

  .download-logo {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_icon {
      padding-left: 10px;

      height: 66px;
    }

    &_text {
      margin-top: 13.5px;

      &.en {
        height: 16px;
      }

      &.zh {
        height: 20px;
      }
    }
  }

  .download-iOS,
  .download-android {
    font-family: PingFang SC;
    font-size: 17px;
    font-weight: 600;
    line-height: 44px;
    text-align: center;
    margin-top: 20px;
    background: $theme-color;
    width: 100%;
    height: 44px;
    border-radius: 4px;
    color: #ffffff;
  }

  .modal-overlay {
    background-color: rgba($color: #000000, $alpha: 0.4);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .modal-body {
      width: 330px;
      height: 250px;
      background: #ffffff;
      border-radius: 8px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;

      .modal-close {
        position: absolute;
        top: -50px;
        right: 0px;
        font-size: 20px;
      }

      .modal-header {
        position: relative;
        top: -21px;
        width: 100%;
        height: 90px;
        background: url('../../public/modalHeader.png') no-repeat center center /
          cover;
        border-radius: 8px 8px 0 0;
        flex: 0 0 auto;
      }

      .modal-content {
        .title {
          text-align: start;
          font-size: 16px;
          font-weight: 500;
          width: 100%;
          height: 16px;
          padding-left: 16px;
          margin-bottom: 10px;
          margin-top: 13px;
        }

        .content {
          text-align: start;
          font-size: 14px;
          font-weight: 400;
          width: 100%;
          height: 16px;
          line-height: 16px;
          padding-left: 16px;
        }
      }

      .modal-link {
        width: 90%;
        margin: auto;
        height: 50px;
        background: #f4f4f4;
        flex: 0 0 auto;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;

        .link {
          text-align: center;
          flex: 1 1 auto;
          width: 90px;
          // line-height: 45px;
          padding-top: 10px;
          word-wrap: break-word;
        }
        .copy {
          background: $theme-color;
          border-radius: 0 16px 16px 0;
          height: 100%;
          width: 65px;
          text-align: center;
          line-height: 45px;
          color: #ffffff;
        }
      }
    }
  }
}
